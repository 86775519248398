'use client'

import { useQuery, useMutation } from '@apollo/client'
import { CountryCode } from '@shopify/hydrogen-react/storefront-api-types'
import { useRouter } from 'next/navigation'
import { createContext, useEffect, useState, useContext, Context } from 'react'

import { graphql } from 'core/clients/graphql/storefront'
import useIsWholesale from 'core/hooks/useIsWholesale'
import useSession from 'core/hooks/useSession'
import session from 'core/session'

const CART_FRAGMENT = `
  #graphql
  fragment CartFragment on Cart {
    id
    checkoutUrl
    totalQuantity
    buyerIdentity {
      countryCode

      purchasingCompany {
        location {
          id
        }
      }

      customer {
        id
        email
        firstName
        lastName
        displayName
      }
      email
      phone
    }
    lines(first: 100) {
      edges {
        node {
          id
          quantity
          attributes {
            key
            value
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
            compareAtAmountPerQuantity {
              amount
              currencyCode
            }
          }

          discountAllocations {
            ... on CartAutomaticDiscountAllocation {
              title
              discountedAmount {
                currencyCode
                amount
              }
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              availableForSale
              compareAtPrice {
                ...MoneyFragment
              }
              price {
                ...MoneyFragment
              }
              requiresShipping
              title
              image {
                ...ImageFragment
              }
              product {
                id
                handle
                title
                collections(first: 10) {
                  nodes {
                    handle
                    isCategory: metafield(namespace: "category", key: "isCategory") {
                      value
                    }
                  }
                }
              }
              selectedOptions {
                name
                value
              }
            }
          }
        }
      }
    }
    cost {
      subtotalAmount {
        ...MoneyFragment
      }
      totalAmount {
        ...MoneyFragment
      }
      totalDutyAmount {
        ...MoneyFragment
      }
      totalTaxAmount {
        ...MoneyFragment
      }
    }
    note
    attributes {
      key
      value
    }
    discountCodes {
      code
    }
  }

  fragment MoneyFragment on MoneyV2 {
    currencyCode
    amount
  }
  fragment ImageFragment on Image {
    id
    url
    altText
    width
    height
  }
`

const CART_CREATE_MUTATION = graphql(`
  #graphql
  mutation CartCreate($input: CartInput) {
    cartCreate(input: $input) {
      userErrors {
        code
        field
        message
      }
      cart {
        ...CartFragment
      }
    }
  }

  ${CART_FRAGMENT}
`)

const CART_UPDATE_IDENTITY_MUTATION = graphql(`
  #graphql
  mutation CartUpdate($buyerIdentity: CartBuyerIdentityInput!, $id: ID!) {
    cartBuyerIdentityUpdate(cartId: $id, buyerIdentity: $buyerIdentity) {
      cart {
        ...CartFragment
      }
    }
  }
  ${CART_FRAGMENT}
`)

const CART_ADD_LINES_MUTATION = graphql(`
  #graphql
  mutation CartAddLines($id: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $id, lines: $lines) {
      cart {
        ...CartFragment
      }
    }
  }

  ${CART_FRAGMENT}
`)
type CART_ADD_LINES_INPUT = Parameters<NonNullable<(typeof CART_ADD_LINES_MUTATION)['__apiType']>>[0]

const CART_REMOVE_LINES_MUTATION = graphql(`
  #graphql
  mutation CartRemoveLines($id: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $id, lineIds: $lineIds) {
      cart {
        ...CartFragment
      }
    }
  }

  ${CART_FRAGMENT}
`)
type CART_REMOVE_LINES_INPUT = Parameters<NonNullable<(typeof CART_REMOVE_LINES_MUTATION)['__apiType']>>[0]

const CART_UPDATE_LINES_MUTATION = graphql(`
  #graphql
  mutation CartUpdateLines($id: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $id, lines: $lines) {
      cart {
        ...CartFragment
      }
    }
  }

  ${CART_FRAGMENT}
`)
type CART_UPDATE_LINES_INPUT = Parameters<NonNullable<(typeof CART_UPDATE_LINES_MUTATION)['__apiType']>>[0]

const CART_QUERY = graphql(`
  #graphql
  query Cart($id: ID!) {
    cart(id: $id) {
      ...CartFragment
    }
  }

  ${CART_FRAGMENT}
`)
type CART_PAYLOAD = NonNullable<ReturnType<NonNullable<(typeof CART_QUERY)['__apiType']>>['cart']>

type CART_CONTEXT_TYPE = {
  checkoutUrl: string
  lines: CART_PAYLOAD['lines']['edges'][number]['node'][]
  cost: CART_PAYLOAD['cost']
  linesAdd: (lines: CART_ADD_LINES_INPUT['lines']) => Promise<void>
  linesRemove: (lineIds: CART_REMOVE_LINES_INPUT['lineIds']) => Promise<void>
  linesUpdate: (lines: CART_UPDATE_LINES_INPUT['lines']) => Promise<void>
}

export const CartContext = createContext({})
export const useCart = (): CART_CONTEXT_TYPE =>
  useContext<CART_CONTEXT_TYPE>(CartContext as unknown as Context<CART_CONTEXT_TYPE>)

function useGetCart(): {
  cart: CART_PAYLOAD | null
  createCart: typeof createCart
} {
  const { shippingCountry, storefrontToken, cartId, location } = useSession()
  const locationId = location ? `gid://shopify/CompanyLocation/${location}` : null
  const [loading, setLoading] = useState(false)
  const { refresh } = useRouter()

  const [createCart, { data: createData }] = useMutation(CART_CREATE_MUTATION, {
    onCompleted(data) {
      const cartId = data?.cartCreate?.cart?.id?.replace('gid://shopify/Cart/', '')

      if (data.cartCreate?.cart === null) {
        session.storefrontToken = ''
        session.customerToken = ''
        refresh()
      }

      if (cartId) {
        session.cartId = cartId
      }
      setLoading(false)
    },
    variables: {
      input: {
        buyerIdentity: {
          customerAccessToken: storefrontToken,
          companyLocationId: !!storefrontToken ? locationId : null,
          countryCode: shippingCountry as CountryCode,
        },
      },
    },
  })

  const { data: loadData } = useQuery(CART_QUERY, {
    variables: {
      id: `gid://shopify/Cart/${cartId}`,
    },
    skip: !!!cartId,
    onCompleted: ({ cart }) => {
      if (!cart) {
        session.cartId = ''
        createCart()
      } else if (cart.buyerIdentity.countryCode && shippingCountry !== cart.buyerIdentity.countryCode) {
        session.shippingCountry = cart.buyerIdentity.countryCode
      }
    },
  })

  useEffect(() => {
    if (loading) return
    setLoading(true)
    if (!cartId) {
      createCart()
    }
  }, [loading, cartId])

  return { cart: loadData?.cart || createData?.cartCreate?.cart || null, createCart }
}

export default function ShopifyProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const { shippingCountry, storefrontToken, location } = useSession()
  const isWholesale = useIsWholesale()
  const locationId = location && `gid://shopify/CompanyLocation/${location}`

  const { cart, createCart } = useGetCart()
  const cartId = cart?.id

  const cartLocationId = cart?.buyerIdentity.purchasingCompany?.location.id
  const cartCountryCode = cart?.buyerIdentity.countryCode

  const [updateIdentity] = useMutation(CART_UPDATE_IDENTITY_MUTATION, {
    onError: () => createCart(),
    onCompleted(data) {
      const cartId = data?.cartBuyerIdentityUpdate?.cart?.id?.replace('gid://shopify/Cart/', '')

      if (cartId) {
        session.cartId = cartId
      }
    },
    variables: {
      id: cartId as string,

      buyerIdentity: {
        customerAccessToken: storefrontToken,
        companyLocationId: locationId,
        countryCode: shippingCountry as CountryCode,
      },
    },
  })
  const [addLines] = useMutation(CART_ADD_LINES_MUTATION)
  const [removeLines] = useMutation(CART_REMOVE_LINES_MUTATION)
  const [updateLines] = useMutation(CART_UPDATE_LINES_MUTATION)

  useEffect(() => {
    if (!cartId || !cartId.includes(session.cartId) || !session.cartId) {
      return
    }

    if ((locationId && locationId !== cartLocationId) || cart?.buyerIdentity.countryCode !== shippingCountry) {
      if (!isWholesale) {
        updateIdentity()
      }
    }
  }, [cartId, location, cartLocationId, cartCountryCode, shippingCountry])

  const checkoutUrl = cart?.checkoutUrl
  const lines = cart?.lines.edges.map(({ node }) => node)
  const cost = cart?.cost

  async function linesAdd(lines: CART_ADD_LINES_INPUT['lines']): ReturnType<typeof addLines> {
    return addLines({
      variables: {
        id: cartId as string,
        lines,
      },
    })
  }

  async function linesRemove(lineIds: CART_REMOVE_LINES_INPUT['lineIds']): ReturnType<typeof removeLines> {
    return removeLines({
      variables: {
        id: cartId as string,
        lineIds,
      },
    })
  }

  async function linesUpdate(lines: CART_UPDATE_LINES_INPUT['lines']): ReturnType<typeof updateLines> {
    return updateLines({
      variables: {
        id: cartId as string,
        lines,
      },
    })
  }

  return (
    <CartContext.Provider value={{ checkoutUrl, lines, cost, linesAdd, linesRemove, linesUpdate }}>
      {children}
    </CartContext.Provider>
  )
}
