import { animated, useTransition } from '@react-spring/web'
import { useParams } from 'next/navigation'
import { useState, useEffect, useRef } from 'react'
import algolia from 'search-insights'

import { getIndexKey } from 'core/clients/algolia'
import useCheckoutUpdateLinesMutation from 'core/hooks/useCheckoutUpdateLinesMutation'
import useIsInViewport from 'core/hooks/useIsInViewport'
import useNotifications from 'core/hooks/useNotifications'
import useOverlayControls from 'core/hooks/useOverlayControls'
import useProductSelection, { processSelection } from 'core/hooks/useProductSelection'
import useSession from 'core/hooks/useSession'
import useSWR from 'core/hooks/useSWR'
import { getPricingFromChannel } from 'core/utils/channel'
import mergeClasses from 'core/utils/mergeClasses'
import { analyticsTracking, cartTracking } from 'core/utils/tracking'

import type { ReturnType } from 'app/api/product/availability/route'

import transitions from 'components/AdamTheme/theme/transitions'
import Preorder from 'components/Preorder'
import SizeSelector from 'components/SizeSelector'

export default function HoverControls({
  product,
  smallControls,
  className,
  style,
  queryID,
  position,
  parentEl,
}: {
  product: Algolia.Product
  position: number
  smallControls?: boolean
  className?: string
  style?: any
  queryID?: string
  parentEl: HTMLDivElement | null
}): JSX.Element | null {
  const { locale } = useParams() || {}
  const { channel, algoliaUserToken, location } = useSession()
  const { show } = useOverlayControls()
  const [updateLines] = useCheckoutUpdateLinesMutation()
  const [fetch, setFetch] = useState(false)
  const [active, setActive] = useState(false)
  const timer = useRef<NodeJS.Timeout | null>(null)
  const isntCompact = useIsInViewport('xl')
  const { add } = useNotifications()

  function mouseEnter(): void {
    timer.current && clearTimeout(timer.current)
    setActive(true)
    setFetch(true)
  }
  function mouseLeave(): void {
    timer.current = setTimeout(() => setActive(false), 300)
  }

  useEffect(() => {
    parentEl?.addEventListener('mouseenter', mouseEnter)
    parentEl?.addEventListener('mouseleave', mouseLeave)
    return () => {
      parentEl?.removeEventListener('mouseenter', mouseEnter)
      parentEl?.removeEventListener('mouseleave', mouseLeave)
    }
  }, [parentEl])

  useEffect(() => {
    return () => {
      timer.current && clearTimeout(timer.current)
    }
  }, [])

  const { data } = useSWR<ReturnType>(
    fetch && locale && channel
      ? `/api/product/availability?channel=${channel}&locale=${locale}&id=${product.objectID}&location=${location}`
      : null
  )
  const { availability } = data || { availability: null }

  const controlTransition = useTransition(active, {
    position: 'absolute',
    leave: {
      transform: `scale(1) rotateY(0) translate(0%, 150%)`,
    },
    enter: {
      transform: `scale(1) rotateY(0) translate(0%, 0%)`,
    },
    from: {
      transform: `scale(1) rotateY(0) translate(0%, 150%)`,
    },
    config: { ...transitions.springMenu, mass: 1.2 },
  })

  const selector = useProductSelection(availability || product.variants)
  if (!selector) {
    return null
  }

  const { sizes, sizesAvailable, selection } = selector

  return (
    <div
      className={mergeClasses(
        'absolute inset-0 w-full items-end justify-center flex p-md pointer-events-none overflow-hidden z-1 ',
        className
      )}
      style={style}
    >
      {controlTransition(
        (styles, theme) =>
          theme && (
            <animated.div
              className="pointer-events-auto rounded-xl bg-ghost p-sm"
              style={styles}
            >
              <Preorder
                small
                date={product.preorderAt}
              />
              <SizeSelector
                compact={!isntCompact || smallControls}
                withHover
                color="grass"
                withLoadingOnClick
                sizes={sizes}
                sizesSelected={selection}
                sizesAvailable={sizesAvailable}
                onChange={async (selection): Promise<void> => {
                  const lines = processSelection(product.variants, selection)?.filter(({ quantity }) => quantity > 0)
                  if (lines) {
                    const { opencart } = await updateLines(lines)
                    setActive(false)

                    if (opencart) {
                      show('cart')
                    } else {
                      add({ type: 'added-cart', context: product })
                    }

                    const variant = product.variants.find(({ id }) => id === lines[0]?.merchandiseId)
                    const variantName = variant?.title
                    const pricing = getPricingFromChannel(product, channel)

                    analyticsTracking({ action: 'click', category: 'plp', label: 'quick-buy' })
                    cartTracking({
                      type: 'add',
                      products: [
                        {
                          name: product.title,
                          id: variant?.sku,
                          variant: variantName,
                          category: product.category,
                          position: position + 1,
                          price: pricing?.price.toFixed(2),
                          quantity: 1,
                        },
                      ],
                      currencyCode: pricing?.currency,
                    })

                    const algoliaData = {
                      userToken: algoliaUserToken,
                      index: getIndexKey(),
                      eventName: 'Product Added to Cart',
                      objectIDs: [product.objectID],
                    }

                    if (queryID) {
                      algolia('clickedObjectIDsAfterSearch', {
                        ...algoliaData,
                        positions: [position + 1],
                        queryID,
                      })
                    } else {
                      algolia('convertedObjectIDs', algoliaData)
                    }
                  }
                }}
              />
            </animated.div>
          )
      )}
    </div>
  )
}
