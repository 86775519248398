import { useContext } from 'react'

import { OverlayContext } from 'core/context/OverlayProvider'
import { OverlayType, OverlayPositions } from 'core/context/OverlayProvider'

function getPositionFromType(type: keyof typeof OverlayType | null): OverlayPositions {
  switch (type) {
    case OverlayType.quickBuy:
      return OverlayPositions.bottom
    case OverlayType.cart:
    case OverlayType.delivery:
    case OverlayType.sizeChart:
      return OverlayPositions.right
    default:
      return OverlayPositions.right
  }
}

export default function useOverlayControls(): {
  show: (
    type: keyof typeof OverlayType,
    options?: {
      context?: any
      position?: keyof typeof OverlayPositions
      component?: JSX.Element
    }
  ) => void
  hide: () => void
  active: boolean
} {
  const { set, type } = useContext(OverlayContext)

  return {
    active: !!type,
    show: (
      type: keyof typeof OverlayType,
      options: {
        context?: any
        position?: keyof typeof OverlayPositions
        component?: JSX.Element
      } = {}
    ) => {
      const position = options?.position || getPositionFromType(type)

      set((prevState) => ({
        ...prevState,
        type,
        options: { ...options, position },
      }))
    },
    hide: () =>
      set((prevState) => ({
        ...prevState,
        type: null,
      })),
  }
}
