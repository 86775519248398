import { CountUp } from 'use-count-up'

import useI18n from 'core/hooks/useI18n'
import type { ProjectDistribution } from 'core/types/greenstory'
import mergeClasses from 'core/utils/mergeClasses'

import Button from 'components/Button'
import Image from 'components/Image'

export default function GreenstoryProject({
  name,
  image_desktop,
  lu_project_type,
  value,
  units,
  overview,
  publish_url,
  location,
  type,
  animatePercentage,
  animationTime,
  small,
  className,
}: ProjectDistribution & {
  units: string
  type?: string
  animatePercentage?: number
  animationTime?: number
  small?: boolean
  className?: string
}): JSX.Element {
  const { t } = useI18n()
  const image = image_desktop?.url || image_desktop

  return (
    <li className={mergeClasses('mb-md', className)}>
      <div className="relative h-[200px]">
        <Image
          alt={name}
          src={image as string}
          aspectRatio="3/2"
          objectFit="cover"
          className="mb-sm w-full"
        />
        <div
          className={mergeClasses(
            'text-xs font-normal absolute right-md top-md py-sm px-[12px] bg-ghost rounded-lg uppercase md:text-sm',
            `text-${'grass'}`
          )}
        >
          {type || lu_project_type.data}
        </div>
        {small && (
          <div className="absolute bottom-md right-md ml-sm flex justify-end whitespace-nowrap text-3xl font-bold leading-none text-ghost ">
            {animatePercentage ? (
              <CountUp
                easing="linear"
                isCounting
                decimalPlaces={0}
                duration={animationTime}
                start={value * animatePercentage}
                end={value}
              />
            ) : (
              value.toFixed(2)
            )}
            <span>{units}</span>
          </div>
        )}
      </div>
      <div className={mergeClasses('flex', small ? 'flex-col' : 'flex-row')}>
        <h4 className="mb-md mt-xs grow">
          {name} <div className="text-sm font-normal">{location.country}</div>
        </h4>
        {!small && (
          <div className="ml-sm flex justify-end whitespace-nowrap text-3xl font-bold leading-none ">
            {animatePercentage ? (
              <CountUp
                easing="linear"
                isCounting
                decimalPlaces={0}
                duration={animationTime}
                start={value * animatePercentage}
                end={value}
              />
            ) : (
              value.toFixed(2)
            )}
            <span className="font-bold">{units}</span>
          </div>
        )}{' '}
      </div>

      {overview && (
        <div
          className="[&br]:hidden"
          dangerouslySetInnerHTML={{ __html: overview.about_project }}
        />
      )}
      {!small && (
        <Button
          styleType="link"
          to={publish_url}
          target="_blank"
          icon="external"
        >
          {t('pdp.greenstory.project.readmore')}
        </Button>
      )}
    </li>
  )
}
