import { useState } from 'react'

import useOnScroll from 'core/hooks/useOnScroll'

const getIsPast = (threshold: number): boolean => {
  const offset = window.scrollY
  return offset > threshold
}

export default function useIsScrolledPast({
  threshold = 20,
  throttleRate = 16,
}: {
  threshold?: number
  throttleRate?: number
}): boolean {
  const [pastThreshold, setPastThreshold] = useState(false)

  useOnScroll(
    () => {
      setPastThreshold(getIsPast(threshold))
    },
    { scope: [threshold], throttleRate }
  )

  return pastThreshold
}
