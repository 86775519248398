'use client'
import { useEffect, useState } from 'react'

export default function useIsTouchDevice(): boolean {
  const [isTouchDevice, setIsTouchDevice] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(`(hover: none) and (pointer: coarse)`)
    setIsTouchDevice(media.matches)
  }, [])

  return isTouchDevice
}
