'use client'

import uniqueId from 'lodash/uniqueId'
import { useSnapshot } from 'valtio'

import notificationsProxy from 'core/notificationsProxy'
import type { Notification } from 'core/types/notification'

export function add({ type, context }: Omit<Notification, 'id'>): void {
  notificationsProxy.notifications.push({
    id: uniqueId(),
    type,
    context,
  })
}

export function remove(uid: string): void {
  notificationsProxy.notifications = notificationsProxy.notifications.filter(({ id }) => id !== uid)
}

export default function useNotifications(): {
  notifications: typeof notifications
  add: typeof add
  remove: typeof remove
} {
  const { notifications } = useSnapshot(notificationsProxy)

  return {
    notifications,
    add,
    remove,
  }
}
