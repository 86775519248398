'use client'

import { ReactNode, createContext, useContext } from 'react'

export const TimeContext = createContext(0)

export function useTimeContext(): number {
  return useContext<number>(TimeContext)
}

export default function TimeProvider({ time, children }: { time: number; children: ReactNode }): JSX.Element {
  return <TimeContext.Provider value={time}>{children}</TimeContext.Provider>
}
