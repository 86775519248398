import useSWRCore, { SWRResponse } from 'swr'

import stringToBoolean from 'core/utils/stringToBoolean'

export default function useSWR<Tobj>(url: string | null, suspense?: boolean): SWRResponse<Tobj, any> {
  const isClient = typeof window !== 'undefined'
  const vercelUrl = process.env.VERCEL_URL
  const host = vercelUrl
    ? `https://${stringToBoolean(process.env.B2B) ? 'b2b.' : ''}a-dam.com`
    : `http://localhost:3000`
  let absoluteUrl = url

  const fetcher = (url: string): Promise<Tobj> => fetch(url).then((res) => res.json())

  if (suspense && !!process.env) {
    absoluteUrl = `${isClient ? '' : host}${url}`
  }

  return useSWRCore(absoluteUrl, fetcher, {
    suspense: !!suspense,
    revalidateOnMount: !!!suspense,
    revalidateOnFocus: !!!suspense,
  })
}
