'use client'

import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

import useIsRouteChanging from 'core/hooks/useIsRouteChanging'
import useUIState from 'core/hooks/useUIState'

export default function useTransparentMenu(transparent = true): void {
  const { setMenuTransparent } = useUIState()
  const { loading } = useIsRouteChanging()
  const pathname = usePathname()

  useEffect(() => {
    if (typeof transparent === 'boolean' && !loading) {
      setMenuTransparent(transparent)
    }
  }, [transparent, pathname, loading])
}
