'use client'

import noop from 'lodash/noop'
import { createContext, useState, SetStateAction, Dispatch } from 'react'
import { ReactNode } from 'react'

export enum OverlayType {
  cart = 'cart',
  delivery = 'delivery',
  quickBuy = 'quickBuy',
  sizeChart = 'sizeChart',
  component = 'component',
  greenstory = 'greenstory',
}

export enum OverlayPositions {
  right = 'right',
  left = 'left',
  bottom = 'bottom',
  center = 'center',
}

export type OverlayContextType = {
  type: keyof typeof OverlayType | null
  options: {
    position: keyof typeof OverlayPositions
    context?: any
    product?: Algolia.Product
    component?: JSX.Element
    gender?: string
    channel?: string
    category?: string
  }
}

interface OverlayContextInterface extends OverlayContextType {
  set: Dispatch<SetStateAction<OverlayContextType>>
}

const defaultOverlayContext: OverlayContextInterface = {
  type: null,
  options: { position: 'bottom' },
  set: noop,
}

export const OverlayContext = createContext<OverlayContextInterface>({ ...defaultOverlayContext })

export default function OverlayProvider({ children }: { children: ReactNode }): JSX.Element {
  const [value, set] = useState<OverlayContextType>({ type: null, options: { position: 'bottom' } })

  return <OverlayContext.Provider value={{ ...value, set }}>{children}</OverlayContext.Provider>
}
