import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

export default function useCookieHide(
  token?: string | null,
  prefix = '',
  days = 7,
  initial = false
): [null | boolean, (arg0: boolean) => void] {
  const [hidden, setHidden] = useState<null | boolean>(initial)
  const cookieToken = `${prefix}_${token}`

  useEffect(() => {
    if (token) {
      setHidden(!!Cookies.get(cookieToken))
    }
  }, [cookieToken, token])

  return [
    hidden,
    (value) => {
      if (value) {
        Cookies.set(cookieToken, 'true', { expires: days, path: '/' })
        setHidden(true)
      } else {
        Cookies.remove(cookieToken)
        setHidden(false)
      }
    },
  ]
}
