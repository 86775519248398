import { useState } from 'react'

import type { sizeType } from 'core/types/size'
import { getVariantFromTitle } from 'core/utils/product'

import type { SizesType } from 'components/SizeSelector'

type LineType = {
  merchandiseId: string
  quantity: number
}

function getSizesFromVariants<Tobj, T extends Tobj & { title: string; sku?: string }[]>(
  variants?: T | null
): sizeType[] {
  if (!variants) {
    return []
  }

  return variants.filter(({ sku }) => !!sku).map((variant) => variant.title as sizeType)
}

export function processSelection<Tobj, T extends Tobj & { id: string; title: string }>(
  variants: T[] | null,
  selection: SizesType
): LineType[] | null {
  if (!variants) return null

  const mappedSelection = Object.keys(selection).map((key) => {
    const merchandiseId = getVariantFromTitle(variants, key as sizeType)?.id

    if (!merchandiseId) return null

    return {
      merchandiseId: `gid://shopify/ProductVariant/${merchandiseId}`,
      quantity: selection[key as sizeType] || 0,
    }
  }) as LineType[]

  return mappedSelection.filter(Boolean)
}

function selectionValid<
  Tobj,
  T extends Tobj & {
    title: string
    quantityInStock: number
  },
>(variants: T[] | null, selection: SizesType): boolean {
  if (!variants) return false

  return Object.keys(selection)
    .map((key) => {
      const variant = getVariantFromTitle(variants, key)
      const stock = variant?.quantityInStock || 0

      return stock >= (selection[key as sizeType] || 0)
    })
    .every(Boolean)
}

export default function useProductSelection<
  Tobj,
  T extends Tobj & {
    id: string
    title: string
    quantityInStock: number
  },
>(
  variants?: T[] | null
): {
  sizes: sizeType[]
  sizesAvailable: SizesType

  setSelection: (arg0: SizesType) => void
  resetSelection: () => void
  emptySelection: SizesType
  selection: SizesType
  selectionAmount: number
  lines: LineType[] | null
  valid: boolean
} | null {
  const sizes = getSizesFromVariants(variants)
  const emptySelection = sizes.reduce((c, size) => ({ ...c, [size]: 0 }), {})
  const [selection, _setSelection] = useState<SizesType>(emptySelection)

  function setSelection(select: SizesType): void {
    _setSelection((current) => ({ ...current, ...select }))
  }

  if (!variants) {
    return null
  }

  const selectionAmount = Object.keys(selection).reduce((last, key) => last + (selection[key as sizeType] || 0), 0)
  const valid = selectionAmount > 0 && selectionValid(variants, selection)
  const lines = processSelection(variants, selection)

  const sizesAvailable = variants.reduce(
    (s, variant) => ({
      ...s,
      [variant.title]: variant.quantityInStock,
    }),
    {}
  )

  return {
    sizes,
    sizesAvailable,

    setSelection,
    resetSelection: () => setSelection(emptySelection),
    emptySelection,
    selection,
    selectionAmount,
    lines,
    valid,
  }
}
