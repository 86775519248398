import { StructuredText, StructuredTextDocument } from 'react-datocms'

import './datoText.css'

export default function DatoText({ children }: { children?: StructuredTextDocument }): JSX.Element {
  return (
    <div className="dato-text">
      <StructuredText data={children} />
    </div>
  )
}
