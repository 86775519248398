'use client'

import { useRef, useState, useEffect } from 'react'

import useI18n from 'core/hooks/useI18n'
import useViewport from 'core/hooks/useViewport'
import mergeClasses from 'core/utils/mergeClasses'
import { stripRootfromUrl } from 'core/utils/url'

import Button from 'components/Button'
import Image from 'components/Image'

import { MediaFragment } from './Media.dato.generated'

export type VideoQualityType = 'low' | 'medium' | 'high'

function StyledButton({ to, ...props }: (typeof Button)['arguments']): JSX.Element {
  return to !== '/' && to ? (
    <Button
      styleType="base"
      className="focus h-full w-full leading-none focus-in"
      {...props}
      to={to}
    />
  ) : (
    <div
      className="h-full w-full leading-none "
      {...props}
    />
  )
}

function useSetVideoQualityBasedOnViewport(
  source: MediaFragment['video'],
  video: HTMLVideoElement | null,
  maxQuality: VideoQualityType,
  allowHLS: boolean
): void {
  const viewport = useViewport()
  const [canPlayHLS, setCanPlayHLS] = useState(true)
  const qualityPriority: VideoQualityType[] = ['low', 'medium', 'high']
  const maxQualityPriority = qualityPriority.indexOf(maxQuality)

  // check video element if it supports hls
  useEffect(() => {
    if (video) {
      setCanPlayHLS(
        allowHLS && !!(video?.canPlayType('application/vnd.apple.mpegURL') || video?.canPlayType('audio/mpegurl'))
      )
    }
  }, [video])

  // fallback to mp4 if hls is not supported
  useEffect(() => {
    if (!!video && !canPlayHLS) {
      const sourcePriority = [source?.low, source?.medium, source?.high].filter(Boolean) as string[]
      const maxSource = sourcePriority.length - 1
      const currentTime = video.currentTime || 0
      const proposedQuality = {
        xs: 0,
        sm: 0,
        md: 1,
        lg: 2,
        xl: 2,
        '2xl': 2,
      }[viewport]

      const quality = proposedQuality > maxQualityPriority ? maxQualityPriority : proposedQuality
      try {
        video.src = sourcePriority[quality >= maxSource ? maxSource : quality] as string
        video.currentTime = currentTime
      } catch (e) {}
    }
  }, [viewport, canPlayHLS, maxQuality])
}

function getSecondsFromTime(time: string | null): number {
  if (!time) return 0

  return time.split(':').reduce((sum, current, index) => sum + Number(current) * (index === 0 ? 60 : 1), 0)
}

export default function Media({
  alt,
  link,
  mimeType,
  video,
  customData,
  image,
  focalPoint,
  sizes,
  priority,
  controls,
  maxQuality = 'low',
  hls = true,
  url,
  aspectRatio,
  blur = true,
  className,
}: Partial<MediaFragment> & {
  sizes?: string
  alt?: string | null
  priority?: boolean
  controls?: boolean
  maxQuality?: 'low' | 'medium' | 'high'
  hls?: boolean
  aspectRatio?: [number, number] | null
  blur?: boolean
  className?: string
}): JSX.Element {
  const { t } = useI18n()

  const mediaRef = useRef<HTMLVideoElement>(null)
  const [muted, setMuted] = useState(true)

  const isVideo = (mimeType ? mimeType.indexOf('video') : -1) !== -1
  const videoElement = mediaRef?.current

  useSetVideoQualityBasedOnViewport(video, videoElement, maxQuality, hls)

  useEffect(() => {
    if (mediaRef?.current) {
      mediaRef.current.muted = muted
    }
  }, [muted])

  useEffect(() => {
    function catchError(): void {
      // do nothing
    }

    if (mediaRef?.current) {
      mediaRef.current.addEventListener('error', catchError)
    }

    return () => {
      if (mediaRef?.current) {
        mediaRef.current.removeEventListener('error', catchError)
      }
    }
  }, [mediaRef?.current])

  return (
    <div className={mergeClasses('w-full h-full', className)}>
      <StyledButton to={stripRootfromUrl(link || '')}>
        {isVideo ? (
          <video
            autoPlay
            playsInline
            loop={true}
            muted={true}
            className="flex h-full w-full flex-col justify-center object-cover"
            style={aspectRatio ? { aspectRatio: `${aspectRatio[0]}/${aspectRatio[1]}` } : {}}
            preload={priority ? 'auto' : 'none'}
            ref={mediaRef}
            poster={`${video?.thumbnail}?time=${getSecondsFromTime(customData['thumbnail'])}`}
          >
            <source
              src={video?.hls}
              type="application/x-mpegURL"
            />
          </video>
        ) : (
          (image?.src || url) && (
            <Image
              blur={blur}
              src={(image?.src || url) as string}
              alt={alt}
              objectFit="cover"
              objectPosition={focalPoint ? `${focalPoint.x * 100}% ${focalPoint.y * 100}%` : '50% 50%'}
              priority={priority}
              quality={80}
              aspectRatio={aspectRatio?.join('/')}
              crop={{
                x: focalPoint?.x as number,
                y: focalPoint?.y as number,
                ar: aspectRatio || null,
              }}
              sizes={sizes}
            />
          )
        )}
      </StyledButton>
      {isVideo && controls && (
        <div className="absolute top-0 h-full w-full">
          <div className="relative mx-auto flex h-full w-full max-w-content items-end justify-end p-md">
            <Button
              styleType="icon"
              icon={muted ? 'mute' : 'sound'}
              onClick={(): void => setMuted(!muted)}
            >
              {muted ? t('button.unmute') : t('button.mute')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
