import throttle from 'lodash/throttle'
import { useEffect } from 'react'

export const isPassiveSupported = (() => {
  let passiveSupported = false

  try {
    const options = Object.defineProperty({}, 'passive', {
      get() {
        passiveSupported = true
      },
    })

    window.addEventListener('test', () => console.log, options)
    window.removeEventListener('test', () => console.log, options)
  } catch (err) {
    passiveSupported = false
  }

  return passiveSupported
})()

export default function useOnScroll(
  callback: () => void,
  { scope = [], throttleRate = 16, skip = false }: { scope?: any[]; throttleRate?: number; skip?: boolean } = {}
): void {
  useEffect(() => {
    if (skip) return

    const hasThrottle = throttleRate > 0
    const onScroll = throttle(callback, throttleRate)
    window.addEventListener('scroll', hasThrottle ? onScroll : callback, isPassiveSupported ? { passive: true } : false)

    hasThrottle ? onScroll() : callback()

    return () => {
      hasThrottle && onScroll.cancel()
      window.removeEventListener('scroll', hasThrottle ? onScroll : callback)
    }
  }, [...scope])
}
