import { animated } from '@react-spring/web'
import { MouseEvent } from 'react'

import mergeClasses from 'core/utils/mergeClasses'

import colors from 'components/AdamTheme/theme/colors'
import Icon from 'components/Icon'
import PulseLoader from 'components/PulseLoader'

// TODO: make this from radio buttons

type SizeType = {
  size: string
  isSelected: boolean
  notify: boolean
  onClick: () => void
  transform: string
  disabled: boolean
  withHover: boolean
  compact: boolean
  loading?: boolean
  color?: keyof typeof colors
}

export default function Size({
  size,
  isSelected,
  notify,
  onClick,
  withHover,
  compact,
  loading,
  disabled,
  transform,
  color = 'ink',
}: SizeType): JSX.Element {
  const onMouseUp = ({ currentTarget }: MouseEvent<HTMLButtonElement>): void => currentTarget && currentTarget.blur()

  return (
    <animated.button
      type="button"
      disabled={disabled || loading}
      onMouseUp={onMouseUp}
      data-cy={'size-option'}
      key={size}
      onClick={onClick}
      style={
        {
          transform,
        } as unknown as React.CSSProperties
      }
      className={mergeClasses(
        'group flex bg-transparent border-ink border-solid border items-center justify-center outline-none cursor-pointer no-highlight focus-xl',
        compact
          ? 'w-[30px] h-[30px] text-sm rounded-lg not-last:mr-xs '
          : 'w-button h-button text-md rounded-xl not-last:mr-sm ',
        notify && 'flex-col text-disabled border-disabled',
        isSelected && `bg-${color} border-${color} text-ghost`,
        !loading && !disabled && withHover && `hover:bg-${color} hover:border-${color} hover:!text-transparent`,
        loading && 'bg-disabled border-disabled text-transparent !cursor-progress',
        !disabled && `active:bg-${color}-active active:border-${color}-active active:text-ghost`,
        !loading &&
          disabled &&
          `text-disabled cursor-not-allowed border-disabled after:content-[''] after:inset-0 after:m-auto after:h-full after:w-[1px] after:absolute after:bg-disabled after:rotate-45`,
        !isSelected &&
          !loading &&
          !disabled &&
          (compact
            ? 'hover:border-[15px] hover:border-grass transition-[border] hover:text-ghost'
            : 'hover:border-[20px] hover:border-grass transition-[border] hover:text-ghost')
      )}
    >
      {notify && (
        <Icon
          color={isSelected ? 'ghost' : 'disabled'}
          className="relative mb-[-8px] mt-[-4px] flex h-xl w-xl translate-x-[0%] translate-y-[0%]"
          name="mail"
        />
      )}
      {loading && (
        <PulseLoader
          color="ghost"
          amount={2}
        />
      )}
      {withHover && !loading && !disabled && (
        <Icon
          name="quickBuy"
          className="!absolute left-1/2 top-1/2  hidden translate-x-[-50%] translate-y-[-50%] group-hover:flex"
          color="ghost"
        />
      )}
      {size}
    </animated.button>
  )
}
