'use client'

import { ReactNode, createContext, useContext } from 'react'

import useCookieHide from 'core/hooks/useCookieHide'

import type { MenuResultType } from 'app/lib/getNavigation'

type MenuContextType = {
  closeBanner: () => void
  hasBanner: boolean
} & MenuResultType

export const MenuContext = createContext({ hasBanner: true } as MenuContextType)

export function useMenuContext(): MenuContextType {
  return useContext<MenuContextType>(MenuContext)
}

export default function MenuProvider({ data, children }: { data: MenuResultType; children: ReactNode }): JSX.Element {
  const publishedTimestamp = new Date(data.publishedAt).getTime()
  const hasBanner = !!data.banner?.value
  const [bannerHidden, closeBanner] = useCookieHide(publishedTimestamp.toString(), 'banner', 7, !hasBanner)

  return (
    <MenuContext.Provider
      value={{ ...data, hasBanner: hasBanner && !bannerHidden, closeBanner: () => closeBanner(true) }}
    >
      {children}
    </MenuContext.Provider>
  )
}
