export default function cleanNextParams<Tobj, T extends Record<string, any> & Tobj>(params: T): T {
  return Object.entries(params).reduce(
    (acc, [key, value]) => {
      const val = typeof value === 'string' ? value?.replace('.rsc', '')?.replace('search', '') : value

      if (key && val) {
        acc[key] = val
      }
      return acc
    },
    {} as Record<string, any>
  ) as T
}
