import { useSearchParams } from 'next/navigation'

import { Channel } from 'core/channel'
import { useProductContext } from 'core/context/ProductProvider'

type ProductParams = {
  locale: string
  channel: Channel
  gender?: string
  category?: string
  product?: string
}

export default function useProductParams(): ProductParams {
  const { params } = useProductContext()
  const searchParams = useSearchParams()

  const mappedSearchParams = [...(searchParams?.entries() || [])].reduce((sum, [key, value]) => {
    return { ...sum, [key]: value }
  }, {})

  return { ...mappedSearchParams, ...params } as ProductParams
}
