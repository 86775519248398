'use client'

import { useSearchParams } from 'next/navigation'
import { useEffect, createContext, useState, ReactNode, useContext } from 'react'

import useSWR from 'core/hooks/useSWR'
import useTransparentMenu from 'core/hooks/useTransparentMenu'
import cleanNextParams from 'core/utils/cleanNextParams'

import fetchPdp from 'app/lib/fetchPdp'

export type ProductContextType = {
  product: Algolia.Product | null
  changing: boolean
  params: Record<string, string | null>
}

interface ProductContextInterface extends ProductContextType {
  goToProduct: (product: Algolia.Product) => Promise<void>
  closeProduct: () => Promise<void>
}

export const ProductContext = createContext<ProductContextInterface>({
  product: null,
  changing: false,
  goToProduct: () => Promise.resolve(),
  closeProduct: () => Promise.resolve(),
  params: {},
})

export const useProductContext = (): ProductContextInterface => useContext<ProductContextInterface>(ProductContext)

type ProductProviderState = {
  productSlug: string | null
  changing: boolean
  cachedProduct: ProductContextType['product']
}

export default function ProductProvider({
  product,
  children,
  params,
}: {
  product: Awaited<ReturnType<typeof fetchPdp>>
  params: Record<string, string | null>
  children: ReactNode
}): JSX.Element {
  useTransparentMenu(false)

  const { locale, channel, gender, category, product: _productSlug } = params
  const readOnlySearchParams = useSearchParams()

  const [{ productSlug, changing, cachedProduct }, setState] = useState<ProductProviderState>({
    cachedProduct: product,
    productSlug: _productSlug || null,
    changing: false,
  })

  async function goToProduct(product: Algolia.Product): Promise<void> {
    setState({
      changing: true,
      cachedProduct: product,
      productSlug: product.handle,
    })

    const searchParams = new URLSearchParams(readOnlySearchParams?.toString())
    const hasParams = !!(searchParams?.size > 0)

    searchParams.delete('query')
    const params = Object.fromEntries(searchParams.entries())
    params.product = product.handle

    if (gender) {
      params.gender = gender
    }
    if (category) {
      params.category = category
    }

    const stringifiedParams = JSON.stringify(cleanNextParams(params)).replace(/\\/g, '')
    const path = hasParams ? `${product.link}?${searchParams.toString()}` : product.link

    const state = {
      __NA: true,
      __PRIVATE_NEXTJS_INTERNALS_TREE: [
        '',
        {
          children: [
            ['locale', locale, 'd'],
            {
              children: [
                ['channel', channel, 'd'],
                {
                  children: [
                    'shop',
                    {
                      children: [`__PAGE__?${stringifiedParams}`, {}],
                    },
                  ],
                },
              ],
            },
            null,
            null,
            true,
          ],
        },
      ],
    }

    window.history.pushState(state, '', locale !== 'en' ? `/${locale}${path}` : path)
    setState((state) => ({ ...state, changing: false }))
  }

  async function closeProduct(): Promise<void> {
    const link =
      (!gender && !category) || (category && !gender) ? '/search' : !category ? `/${gender}` : `/${gender}/${category}`

    const searchParams = new URLSearchParams(readOnlySearchParams?.toString())
    const hasParams = !!(searchParams?.size > 0)

    const path = hasParams ? `${link}?${searchParams.toString()}` : link
    const params = Object.fromEntries(searchParams.entries())

    if (gender) {
      params.gender = gender
    }
    if (category) {
      params.category = category
    }

    const stringifiedParams = JSON.stringify(cleanNextParams(params)).replace(/\\/g, '')
    const page = Object.keys(params).length > 0 ? `__PAGE__?${stringifiedParams}` : '__PAGE__'

    const state = {
      __NA: true,
      __PRIVATE_NEXTJS_INTERNALS_TREE: [
        '',
        {
          children: [
            ['locale', locale, 'd'],
            {
              children: [['channel', channel, 'd'], { children: ['shop', { children: [page, {}] }] }],
            },
            null,
            null,
            true,
          ],
        },
      ],
    }

    setState((state) => ({ ...state, productSlug: null }))
    window.history.pushState(state, '', locale !== 'en' ? `/${locale}${path}` : path)
  }

  function closeIfOverview(): void {
    const isOverview = (window.location.pathname.match(/\//g) || []).length < 3
    if (isOverview) {
      setState((state) => ({ ...state, productSlug: null }))
    }
  }

  const shouldLoadFromAPI = !!(!changing && productSlug && productSlug !== cachedProduct?.handle)

  const { data } = useSWR<Algolia.Product>(
    shouldLoadFromAPI ? `/api/product/pdp?slug=${productSlug}&channel=${channel}&locale=${locale}` : ''
  )

  useEffect(() => {
    setState((state) => ({ ...state, productSlug: _productSlug || null }))
  }, [_productSlug, category])

  useEffect(() => {
    data && setState((state) => ({ ...state, cachedProduct: data }))
  }, [data])

  useEffect(() => {
    setState((state) => ({ ...state, cachedProduct: product }))
  }, [product])

  useEffect(() => {
    window.addEventListener('popstate', closeIfOverview)
    return () => {
      window.removeEventListener('popstate', closeIfOverview)
    }
  }, [])

  return (
    <ProductContext.Provider
      value={{
        product: cachedProduct,
        changing,
        goToProduct,
        closeProduct,
        params: { ...params, product: productSlug },
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
