import { useContext } from 'react'

import { ProductContext } from 'core/context/ProductProvider'

export default function useProductControls(): {
  open: typeof goToProduct
  close: typeof closeProduct
  product: Algolia.Product | null
  changing: boolean
} {
  const { goToProduct, closeProduct, product, changing } = useContext(ProductContext)

  return { open: goToProduct, close: closeProduct, product, changing }
}
