import { initGraphQLTada } from 'gql.tada'

import type { introspection } from 'core/schema/graphql-shopify-storefront.d.ts'

import getClient from './getClient'

export default function storefront(): ReturnType<typeof getClient> {
  const url = `https://${process.env.NEXT_PUBLIC_SHOPIFY_SHOP}/api/${process.env.NEXT_PUBLIC_SHOPIFY_VERSION}/graphql.json`

  return getClient(url, { 'X-Shopify-Storefront-Access-Token': '01249fd96c5bd77981f8b55d4da2a7c1' })
}

export const graphql = initGraphQLTada<{
  introspection: introspection
}>()
