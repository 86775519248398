import { useCart } from 'core/context/ShopifyProvider'

import type { LineType, NewLineType, ExistingLineType } from 'components/CartItem/types'

export default function useCheckoutUpdateLinesMutation(): [(lines: LineType[]) => Promise<any>] {
  const { linesAdd, lines, linesUpdate, linesRemove } = useCart()

  return [
    async function updateLines(cartLines: LineType[]): Promise<any> {
      const newLines: NewLineType[] = []
      const linesToUpdate: ExistingLineType[] = []
      const linesToRemove: string[] = []

      cartLines?.forEach((line) => {
        if ('id' in line && line.id) {
          if (line.quantity === 0) {
            linesToRemove.push(line.id)
          } else {
            linesToUpdate.push(line)
          }

          return
        }

        if ('merchandiseId' in line && line.merchandiseId) {
          newLines.push({
            merchandiseId: line.merchandiseId,
            quantity: line.quantity,
          })

          return
        }
      })

      if (newLines?.length) {
        await linesAdd(newLines)
      }

      if (linesToUpdate?.length) {
        await linesUpdate(linesToUpdate)
      }

      if (linesToRemove?.length) {
        await linesRemove(linesToRemove)
      }

      const opencart = (lines?.length || 0) < 1
      return { opencart }
    },
  ]
}
