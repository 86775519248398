'use client'

import noop from 'lodash/noop'
import { usePathname } from 'next/navigation'
import { useEffect, useRef, useState, createContext, SetStateAction, Dispatch, ReactNode } from 'react'

import { useMenuContext } from 'core/context/MenuProvider'
import useIsScrolledPastElement from 'core/hooks/useIsScrolledPastElement'
import useSession from 'core/hooks/useSession'

import sizes from 'components/AdamTheme/theme/sizes'
import viewports from 'components/AdamTheme/theme/viewports'

type MenuStateType = {
  stickyRef: HTMLDivElement | null
  stickyActive: boolean
  transparent: boolean
  forcedMenu: boolean
}

type Processing = { [key: string]: boolean }

export type UIStateType = {
  menu: MenuStateType
  processing: Processing
  viewport: ViewportType
}

export interface UIStateContextInterface extends UIStateType {
  setMenu: Dispatch<SetStateAction<MenuStateType>>
  setMenuTransparent: (arg0: boolean) => void
  setProcessing: (arg0: string, arg1: boolean) => void
  scrollToSticky: () => void
  disableScroll: () => void
  enableScroll: () => void
}

const defaultUIState: UIStateType = {
  processing: {},
  viewport: 'xs',
  menu: {
    stickyActive: false,
    stickyRef: null,
    transparent: false,
    forcedMenu: false,
  },
}

export const UIStateContext = createContext<UIStateContextInterface>({
  ...defaultUIState,
  setMenu: noop,
  setMenuTransparent: noop,
  setProcessing: noop,
  scrollToSticky: noop,
  disableScroll: noop,
  enableScroll: noop,
})

function useBodyScrollControl(): { disableScroll: () => void; enableScroll: () => void } {
  const [scrollDisabledAmount, setScrollDisabledAmount] = useState<number>(0)
  const [initialWidth, setInitialWidth] = useState<string | null>(null)
  const scrolldisabled = scrollDisabledAmount > 0

  function disableScroll(): void {
    setScrollDisabledAmount((prev) => prev + 1)
  }

  function enableScroll(): void {
    setScrollDisabledAmount((prev) => prev - 1)
  }

  useEffect(() => {
    if (scrolldisabled) {
      setInitialWidth(document.body.style.width)
      document.body.style.width = '100%'
      document.body.style.overflowX = 'hidden'
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.width = initialWidth as string
      document.body.style.overflowX = ''
      document.body.style.overflowY = ''
    }
  }, [scrolldisabled])

  return { disableScroll, enableScroll }
}

export type ViewportType = 'xs' | keyof typeof viewports
function useViewport(): ViewportType {
  const [match, setMatch] = useState<ViewportType>('xs')

  useEffect(() => {
    function listener(): void {
      let newMatches = {}
      for (const [viewport, minWidth] of Object.entries(viewports)) {
        const media = window.matchMedia(`screen and (min-width: ${minWidth}px)`)

        newMatches = {
          ...newMatches,
          [viewport]: media.matches,
        }
      }

      let _match: [string, number] = ['xs', 0]
      for (const [viewport, minWidth] of Object.entries(viewports)) {
        const isMatch = !!newMatches[viewport as keyof typeof newMatches]

        if (isMatch && minWidth > _match[1]) {
          _match = [viewport, minWidth]
        }
      }

      if (match !== _match[0]) {
        setMatch(_match[0] as ViewportType)
      }
    }

    listener()
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [])

  return match
}

export default function UIStateProvider({ children }: { children: ReactNode }): JSX.Element {
  const menuData = useMenuContext()
  const pathname = usePathname()
  const { options } = useSession()

  const path = useRef<string | null>(null)
  const [menu, setMenu] = useState<UIStateType['menu']>(defaultUIState.menu)
  const [processing, _setProcessing] = useState<UIStateType['processing']>(defaultUIState.processing)

  const stickyRef = menu.stickyRef
  const stickyActive = useIsScrolledPastElement(stickyRef, menuData.hasBanner ? -sizes.banner : 0, [options])

  const { disableScroll, enableScroll } = useBodyScrollControl()
  const viewport = useViewport()

  const setProcessing: UIStateContextInterface['setProcessing'] = (key, value) => {
    const updatedValue = { [key]: value }

    _setProcessing({ ...processing, ...updatedValue })
  }

  function setMenuTransparent(transparency: boolean): void {
    if (path.current !== pathname || path.current === null) {
      path.current = pathname
      setMenu((prev) => ({ ...prev, transparent: transparency }))
    }
  }

  useEffect(() => {
    setMenu((prev) => ({ ...prev, stickyActive: !!stickyRef && stickyActive }))
  }, [stickyActive, stickyRef])

  return (
    <UIStateContext.Provider
      value={{
        processing,
        setProcessing,
        menu,
        setMenu,
        setMenuTransparent,
        disableScroll,
        enableScroll,
        viewport,
        scrollToSticky: () => {
          const correction = menuData?.banner?.value ? sizes.banner : 0
          window.scrollTo({ top: (stickyRef?.offsetTop || 0) + correction })
        },
      }}
    >
      {children}
    </UIStateContext.Provider>
  )
}
