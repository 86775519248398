import recommend from '@algolia/recommend'
import algolia from 'algoliasearch'

const client = algolia(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY)

export function getIndexKey(/* isB2B: boolean */): string {
  return 'Products'
  // return isB2B ? 'B2B' : 'B2C'
}

export async function getIndex(/* isB2B: boolean */): Promise<ReturnType<typeof client.initIndex>> {
  return client.initIndex(getIndexKey(/* isB2B */))
}

export async function getCatalogsIndex(/* isB2B: boolean */): Promise<ReturnType<typeof client.initIndex>> {
  return client.initIndex('Catalogs')
}

export async function getAutocomplete(): Promise<ReturnType<typeof client.initIndex>> {
  return client.initIndex('B2C_query_suggestions')
}

export function getRecommend(): ReturnType<typeof recommend> {
  return recommend(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY)
}

export default client
