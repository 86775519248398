import debounce from 'lodash/debounce'
import { useEffect, useState } from 'react'

import useIsRouteChanging from 'core/hooks/useIsRouteChanging'
import useIsScrolledPast from 'core/hooks/useIsScrolledPast'

export default function useIsScrolledPastElement(el: HTMLElement | null, offset?: number, scope: any[] = []): boolean {
  const [threshold, setThreshold] = useState<number>(0)
  const isScrollAfterThreshold = useIsScrolledPast({ threshold })
  const { loading } = useIsRouteChanging()

  useEffect(() => {
    function calculateThreshold(): void {
      setThreshold(!el ? 0 : el.offsetTop + (offset || 0))
    }

    el && calculateThreshold()
    const throttleCalculateThreshold = debounce(calculateThreshold, 1500)
    window.addEventListener('resize', throttleCalculateThreshold)

    return () => {
      window.removeEventListener('resize', throttleCalculateThreshold)
    }
  }, [el, offset, loading, ...scope])

  return !!el && threshold > 0 && isScrollAfterThreshold
}
