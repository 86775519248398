import { to } from '@react-spring/web'
import { useState, useEffect } from 'react'

import type { sizeType } from 'core/types/size'

import colors from 'components/AdamTheme/theme/colors'

import Size from './Size'

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T
}

export type SizesType = PartialRecord<sizeType, number>
export type SizeSelectorType = {
  sizes: (keyof SizesType)[]
  sizesSelected: SizesType
  sizesAvailable: SizesType
  withLoadingOnClick?: boolean
  withHover?: boolean
  compact?: boolean
  onChange: (arg0: SizesType) => Promise<void>
  color?: keyof typeof colors
}

export default function SizeSelector({
  sizes,
  sizesSelected,
  sizesAvailable,
  withLoadingOnClick,
  withHover,
  compact,
  onChange,
  onNotify,
  wobble,
  color,
}: SizeSelectorType & {
  onNotify?: (arg0: string) => void
  wobble?: { x: number }[]
}): JSX.Element {
  const [items, setItems] = useState<SizesType>(sizesSelected)
  const [loadingSize, setLoadingSize] = useState<sizeType | null>(null)
  const withNotification = !!onNotify

  const selectedSize = Object.keys(items).find((size) => items[size as sizeType] || 0 > 0)

  const emptySizes = sizes.reduce(
    (set, size) => ({
      ...set,
      [size]: 0,
    }),
    {}
  )

  async function changeHandler(size: string, value: number, isAvailable: boolean): Promise<void> {
    const sizesSet: SizesType = { ...emptySizes, [size]: value }
    setItems(sizesSet)

    if (isAvailable) {
      await onChange(sizesSet)
    } else {
      await onChange(sizesSet)
      onNotify && onNotify(size)
    }

    withLoadingOnClick && setLoadingSize(null)
  }

  useEffect(() => {
    setItems(sizesSelected)
  }, [sizesSelected])

  return (
    <div className="flex text-sm font-bold no-select">
      {sizes.map((size, index: number) => {
        const isSelected = size === selectedSize
        const isAvailable = (sizesAvailable[size] || 0) > 0
        const transform = !!wobble ? to([wobble[index]?.x], (x) => `translateY(${x}px)`) : 'none'

        return (
          <Size
            key={`size-${size}`}
            size={size}
            loading={loadingSize === size}
            withHover={!!withHover}
            notify={withNotification && !isAvailable}
            isSelected={isSelected}
            onClick={(): void => {
              changeHandler(size, isSelected ? 0 : 1, isAvailable)
              withLoadingOnClick && setLoadingSize(size)
            }}
            compact={!!compact}
            color={color}
            transform={transform as string}
            disabled={!withNotification && !isAvailable}
          />
        )
      })}
    </div>
  )
}
