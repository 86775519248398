import { useEffect, useState } from 'react'

import { Channel } from 'core/channel'
import useIsScrolledPastElement from 'core/hooks/useIsScrolledPastElement'
import { getPricingFromChannel } from 'core/utils/channel'
import { impressionTracking } from 'core/utils/tracking'

export default function useImpressionTracker({
  element,
  product,
  channel,
  position,
}: {
  element: HTMLElement | null
  product: Algolia.Product
  channel: Channel
  position?: number
}): null {
  const hasImpression = useIsScrolledPastElement(element)
  const [impressed, setImpressed] = useState(hasImpression)
  const pricing = getPricingFromChannel(product, channel)

  useEffect(() => {
    if (impressed) {
      impressionTracking({
        products: product.variants.map(({ sku, title }) => ({
          id: sku,
          variant: title,
          name: product.title,
          category: product.category,
          quantity: 1,
          price: pricing?.price.toFixed(2),
          position,
        })),
        currencyCode: pricing?.currency,
      })
    }
  }, [impressed])

  useEffect(() => {
    if (hasImpression) {
      setImpressed(true)
    }
  }, [hasImpression])

  return null
}
