'use client'

import { useEffect, useState } from 'react'

import viewports from 'components/AdamTheme/theme/viewports'

import useViewport, { ViewportType } from './useViewport'

export default function useIsInViewport(viewport: ViewportType): boolean {
  const match = useViewport()
  const [isIn, setIsIn] = useState(false)

  useEffect(() => {
    const viewportsToPriority: ViewportType[] = ['xs', ...Object.keys(viewports).map((vp) => vp as ViewportType)]
    const matchIndex = viewportsToPriority.indexOf(match)
    const isViewportIndex = viewportsToPriority.indexOf(viewport)

    setIsIn(matchIndex >= isViewportIndex)
  }, [match, viewports])

  return isIn
}
