import { useEffect, useState } from 'react'

import { useRouteChangeContext } from 'core/context/RouteChangeProvider'

export default function useIsRouteChanging(): { loading: boolean } {
  const { routeChangeStartCallbacks, routeChangeCompleteCallbacks } = useRouteChangeContext()
  const [loading, setLoading] = useState(false)

  function start(): void {
    setLoading(true)
  }

  function complete(): void {
    setLoading(false)
  }

  useEffect(() => {
    // add callback to the list of callbacks and persist it
    routeChangeStartCallbacks.push(start)
    routeChangeCompleteCallbacks.push(complete)

    return () => {
      // Find the callback in the array and remove it.
      const startIndex = routeChangeStartCallbacks.indexOf(start)
      if (startIndex > -1) {
        routeChangeStartCallbacks.splice(startIndex, 1)
      }
      const completeIndex = routeChangeCompleteCallbacks.indexOf(complete)
      if (completeIndex > -1) {
        routeChangeCompleteCallbacks.splice(completeIndex, 1)
      }
    }
  }, [routeChangeStartCallbacks, routeChangeCompleteCallbacks])

  return { loading }
}
