'use client'

import { useRef } from 'react'
import algolia from 'search-insights'

import { Channel } from 'core/channel'
import { getIndexKey } from 'core/clients/algolia'
import useI18n from 'core/hooks/useI18n'
import useImpressionTracker from 'core/hooks/useImpressionTracker'
import useIsWholesale from 'core/hooks/useIsWholesale'
import useOverlayControls from 'core/hooks/useOverlayControls'
import useProductControls from 'core/hooks/useProductControls'
import useProductParams from 'core/hooks/useProductParams'
import useSession from 'core/hooks/useSession'
import { getPricingFromChannel } from 'core/utils/channel'
import mergeClasses from 'core/utils/mergeClasses'

import viewports from 'components/AdamTheme/theme/viewports'
import Button from 'components/Button'
import Image from 'components/Image'
import Price from 'components/Price'
import ProductLabel from 'components/ProductLabel'

import HoverControls from './HoverControls'

export default function ProductCard({
  product,
  channel,
  url,
  smallControls,
  sizes,
  blur = true,
  priority,
  queryID,
  position,
  touchDevice,
  onClick,
  direct,
  hidePricing,
  className,
}: {
  channel: Channel
  product: Algolia.Product
  url: string
  sizes?: string
  smallControls?: boolean
  position?: number
  blur?: boolean
  priority?: boolean
  queryID?: string
  touchDevice?: boolean
  onClick?: (event: MouseEvent) => void
  direct?: boolean
  hidePricing?: boolean
  className?: string
}): JSX.Element | null {
  const { t } = useI18n()
  const { algoliaUserToken } = useSession()
  const { locale } = useProductParams()
  const isWholesale = useIsWholesale()
  const { show } = useOverlayControls()
  const { open } = useProductControls()

  const translations = product[locale as Algolia.LocalizationKey]
  const title = !!translations && translations.title ? translations.title : product.title

  const pricing = getPricingFromChannel(product, channel)
  const elementRef = useRef<HTMLDivElement | null>(null)

  useImpressionTracker({
    element: elementRef.current,
    product,
    channel,
    position,
  })

  if (!!!pricing) return null

  const isPack = !isWholesale && (product.discounts?.length || 0) > 0
  const onSale = product.onSale || (pricing?.compareAtPrice || 0) > pricing?.price

  function sendClickToAlgolia(): void {
    const indexKey = getIndexKey()
    if (queryID) {
      algolia('clickedObjectIDsAfterSearch', {
        index: indexKey,
        eventName: 'Product Clicked',
        queryID,
        objectIDs: [product.objectID],
        positions: [position || 0],
        userToken: algoliaUserToken,
      })
    } else {
      algolia('clickedObjectIDs', {
        index: indexKey,
        eventName: 'Product Clicked',
        objectIDs: [product.objectID],
        userToken: algoliaUserToken,
      })
    }
  }

  async function handleOnClick(event: any): Promise<void> {
    sendClickToAlgolia()

    if (!direct) {
      open(product)
      event?.preventDefault()
    }

    onClick && onClick(event)
  }

  let thumbnail = product.thumbnail

  return (
    <div
      ref={elementRef}
      className={mergeClasses(
        'flex flex-col shrink bg-pillow opacity-100 mt-xs transition-all duration-300 ease-in-out-quad',
        className
      )}
    >
      <div className="relative aspect-[4/6] leading-none">
        <Button
          to={url}
          styleType="base"
          prefetch={false}
          onClick={handleOnClick}
          className="focus-sm flex aspect-[4/6] w-full focus-in"
        >
          <Image
            priority={priority}
            src={thumbnail}
            alt={title}
            sizes={sizes || `(min-width: ${viewports.md}px) 33vw, (min-width: ${viewports.lg}px) 25vw, 50vw`}
            objectFit="cover"
            quality={80}
            blur={blur}
          />
          {isPack && (
            <div className="absolute left-md top-md rounded-lg bg-ghost px-[12px] py-sm text-sm font-normal uppercase text-cornflower md:text-md">
              Save
              <br />
              with
              <br />
              pack
            </div>
          )}
          {!hidePricing && (
            <ProductLabel
              product={product}
              channel={channel}
            />
          )}
        </Button>
        {!hidePricing && !touchDevice && (
          <HoverControls
            smallControls={smallControls}
            product={product}
            queryID={queryID}
            position={position || 0}
            parentEl={elementRef.current}
          />
        )}
      </div>
      {!hidePricing && (
        <div className="flex w-full grow flex-row items-center justify-between bg-ghost pt-xs touch:pt-sm">
          <Button
            styleType="base"
            className="focus-sm flex w-full justify-between bg-ghost text-md font-bold touch:flex-col touch:items-start"
            to={url}
            onClick={handleOnClick}
          >
            <div className="inline-flex whitespace-break-spaces text-left text-md leading-4">{title}</div>
            <Price
              className="touch:text-md"
              price={pricing.price}
              undiscountedPrice={onSale ? pricing?.compareAtPrice || 0 : 0}
              currencyCode={pricing.currency}
            />
          </Button>

          <Button
            className="hidden aspect-[1/1] !h-button !w-button rounded-xl border border-solid border-ink p-0 touch:flex"
            styleType="icon"
            icon="quickBuy"
            tracking={{
              category: 'plp',
              label: 'open-quick-buy',
            }}
            onClick={(): void => {
              show('quickBuy', { context: { product, queryID } })
              sendClickToAlgolia()
            }}
          >
            {t('pdp.add-to-bag')}
          </Button>
        </div>
      )}
    </div>
  )
}
