import useIsClient from 'core/hooks/useIsClient'
import mergeClasses from 'core/utils/mergeClasses'

export default function Preorder({ date, small }: { date?: string | false; small?: boolean }): JSX.Element | null {
  const client = useIsClient()

  if (!client || !date) {
    return null
  }

  const today = new Date()
  const releaseDate = new Date(date)

  if (releaseDate < today) {
    return null
  }

  const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language
  const dateObj = new Date(date)

  return (
    <div
      className={mergeClasses(
        'text-ghost text-center w-full py-sm px-md mb-md rounded-lg bg-ocean',
        small ? 'text-md' : 'text-xl'
      )}
    >
      Estimated delivery date:{small ? <br /> : ' '}
      <b>
        {window.Intl.DateTimeFormat(locale, {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        }).format(dateObj)}
      </b>
    </div>
  )
}
