'use client'

export type { ViewportType } from 'core/context/UIStateProvider'
import { ViewportType } from 'core/context/UIStateProvider'

import useUIState from './useUIState'

export default function useViewport(): ViewportType {
  return useUIState().viewport
}
