import { useState, useEffect } from 'react'

export default function useIsClient(): boolean {
  const [isClient, setClient] = useState(false)
  useEffect(() => {
    setClient(true)
  }, [])

  return isClient
}
