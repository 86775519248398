import { Channel } from 'core/channel'
import { useTimeContext } from 'core/context/TimeProvider'
import useI18n from 'core/hooks/useI18n'
import { getPricingFromChannel } from 'core/utils/channel'
import mergeClasses from 'core/utils/mergeClasses'

import type { colorType } from 'components/AdamTheme/theme/colors'

function Container({
  emphasis,
  color,
  children,
}: {
  emphasis?: boolean
  color: colorType
  children: React.ReactNode
}): JSX.Element {
  return (
    <div
      className={mergeClasses(
        `absolute right-md top-md py-sm px-[12px] rounded-lg uppercase`,
        emphasis
          ? `text-md font-bold text-ghost bg-${color} md:text-2xl`
          : `text-sm font-normal text-${color} bg-ghost md:text-md`
      )}
    >
      {children}
    </div>
  )
}

export default function ProductLabel({
  product,
  channel,
}: {
  product: Algolia.Product
  channel: Channel
}): JSX.Element | null {
  const { t } = useI18n()
  const pricing = getPricingFromChannel(product, channel)
  const publishedAt = product.publishedAt
  const time = useTimeContext()

  if (pricing?.discountPercentage) {
    return <Container color="crimson">{pricing.discountPercentage}%</Container>
  }

  if (product.bestseller) {
    return <Container color="cornflower">{t('label.bestseller')}</Container>
  }

  if (product.preorderAt) {
    const today = new Date(time)
    const releaseDate = new Date(product.preorderAt)

    if (releaseDate > today) {
      return (
        <Container
          color="ocean"
          emphasis
        >
          {t('label.preorder')}
        </Container>
      )
    }
  }

  if (publishedAt) {
    const oneMonthsBack = new Date(time)
    oneMonthsBack.setMonth(oneMonthsBack.getMonth() - 2)

    const publishedAtDate = new Date(publishedAt)

    if (publishedAtDate > oneMonthsBack) {
      return <Container color="grass">{t('label.new')}</Container>
    }
  }

  if (product.restockAt) {
    const threeWeeksBack = new Date(time)
    threeWeeksBack.setMonth(threeWeeksBack.getDay() - 21)
    if (threeWeeksBack.getTime() < product.restockAt) {
      return <Container color="grass">{t('label.restock')}</Container>
    }
  }

  return null
}
