const easeInOutSine = 'cubic-bezier(0.37, 0, 0.63, 1)'
const easeInOutQuad = 'cubic-bezier(0.45, 0, 0.55, 1)'
const easeInQuad = 'cubic-bezier(0.11, 0, 0.5, 0)'
const easeInOutCubic = 'cubic-bezier(0.65, 0, 0.35, 1)'
const easeOutCubic = 'cubic-bezier(0.33, 1, 0.68, 1)'

const transitions = Object.freeze({
  transformSlow: `transform 700ms ${easeInOutSine}`,
  transitionSlow: `230ms ${easeInOutQuad}`,
  transitionFast: `150ms ${easeInOutCubic}`,
  transitionIn: `200ms ${easeOutCubic}`,
  transitionOut: `230ms ${easeInQuad}`,
  transitionColor: '150ms',
  springMenu: {
    mass: 1,
    tension: 370,
    friction: 40,
  },
})

export default transitions
